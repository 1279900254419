import $ from 'jquery';

window.Downloads = window.Downloads || {}
window.Downloads.New = function () {}

window.Downloads.New.submit = function (token) {
  $('#new_download').submit()
}

// recaptcha requires a function in the global namespace
window.submitNewDownloadForm = Downloads.New.submit
