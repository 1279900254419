import $ from 'jquery';

$(document).on('turbolinks:load', function () {

  $('a.download').on('click', function () {
    gtag('event', 'view_item', {
      'event_category': 'DataFile',
      'event_label': event.currentTarget.href
    })
  })
})
